

.uil{
  color: black;
  display: block;
  cursor: pointer;
}

.flex {
  display: flex;
}




.container{
  
  background-color: #D1F0F3;
  padding: 2rem;
  border-radius: 16px;
  margin-block: 4rem;
  display:  grid;
  grid-template-columns: 1.5fr 6fr;
  gap: 1rem;
  border: 1px solid rgb(210, 217, 238);
  max-width: 40vw !important;
  
}


.navigation{
  padding-left: 1rem;
  color: #588387;
}



.mainBG{
  min-height: 100vh;
  background-image: linear-gradient(145deg, #90dae1, #99dae1);
  overflow:auto;
}



.main-container{
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(1, 1fr);
}

.box{
  display: grid;
  justify-content: center;
  align-items: center;

  color: #fdfefe6b;
  border-radius: 15px;
  background-image: linear-gradient(145deg, #D1F0F3, #D2F0F3);
  place-items: center;
  
  
}







.navLinksDots{
  color: #324B4D;
  text-decoration: none;
  display: inline-block;
  font-family: 'Inter custom',sans-serif;
  font-size: large;
  cursor:pointer;
  height: 30%;
  width: 30%;
  
}






.navLinks{
  color: #324B4D;
  text-decoration: none;
  justify-content: center;
  display: inline-block;

  padding: .25rem;
  font-family: 'Inter custom',sans-serif;
  font-size: large;
  
  
  
}




.navLinks:hover{
  background-color: #88dae1;
  color: black;
  border-radius: .5rem;
}


.navContainer{
  padding: 1rem;
  list-style: none;
  display: flex;
  gap: 1rem;
  align-items: center;


  text-align: center;
  position:absolute;

}


.navLeft{
  padding: 1rem;
  float: left;
  list-style: none;
  display: flex;
  gap: 1rem;
  align-items: center;

  margin-right: auto;
 
  
}

.navRight{
  padding: 1rem;
  float: right;
  list-style: none;
  display: flex;
  gap: 1rem;
  align-items: center;

  margin-left:auto;
  
}

















/* CSS */
.button-18 {
  align-items: center;
  background-color: #588387;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.button-18:hover,
.button-18:focus{ 
  background-color: #a0dde2;
  color: #63a9af;
}


.button-18:disabled { 
  background-color: #a0dde2;
  color: #ffffff;
}



.actionHeader{
  
  
  
  text-align: center;
  padding: .25rem;
  font-family: 'Inter custom',sans-serif;
 
  
  color: black;
}

.actionInputs{
  
  opacity: 1;
  border-radius: 5px;
  border: 1px #D1F0F3;
  width:100%;
  text-align: center;
  background-color: #C6DEF0;
  
 
  

}

.generalText{
 
  
  width: 100%;
  text-align: center;
  justify-self: center;
 
  font-family: 'Inter custom',sans-serif;
 
  
  color: black;
  
}


.justToCenter{
  justify-content: center;
  text-align: center;
  margin: 10;
}


.connectWalletAlert{
 
  
  width: 100%;
  text-align: center;
  
 
  font-family: 'Inter custom',sans-serif;
  border-radius: 6px;
  background-color: #c7f0f5;
  color: #458188;
  margin: 10;
  font-weight: bold;
  font-size: large;
  
}



.transactionText{
 
  
  width: 100%;
  text-align: center;
  overflow:auto;
  font-size: medium;
  font-family: 'Inter custom',sans-serif;
 
  
  color: rgb(168, 33, 33);
  
}




.lds-circle {
  display: block;
  transform: translateZ(1px);
  margin: auto;
  text-align: center;
}
.lds-circle > div {

  width: 32px;
  height: 32px;
  text-align: center;
  margin: auto;

  border-radius: 50%;
  background-image: linear-gradient(145deg, #8ad1d8, #88dae1);
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}





.dropDown{
  position: relative;
  display: inline-block;
}

.dropDown-menu {
  position: absolute;
  left: 0;
  top: calc(100% + .25rem);
  background-color: white;
  padding: .75rem;
  border-radius: .25rem;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.3);
  opacity: 0;
  cursor: default;
  transform: translateY(-10px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;

  
}



.dropDown > .navLinksDots:focus + .dropDown-menu {

  
  opacity: 1;
  transform: translateY(0);
  cursor:pointer;
}


.information-grid {
  display: grid;
  grid-template-columns: repeat(1, max-content);
  gap: 2rem;
  margin: auto;
  text-align: left;
}



.dropdown-heading{
    color: #324B4D;
    
    
    padding: .25rem;
    font-family: 'Inter custom',sans-serif;
    font-weight: bold;
    
}

.managerTools{
  border-radius: 5px;
  border: 1px solid #324b4d79;
}


.dropdown-links{
  text-decoration: none;
  color: black;
  font-family: 'Inter custom',sans-serif;
}

.dropdown-links:hover{
  background-color: #88d9e142;
  color: black;
  border-radius: .5rem;
  font-weight: bolder;
}

.dropDownHeaderText{
  font-weight: bolder;
  font-family: 'Inter custom',sans-serif;
}


.inputHeadings{
  color: black;
  font-family: 'Inter custom',sans-serif;
  text-align: center;
  font-style: italic;
  font-size: large;
}

.termsAndConditionsDiv{
  color: #010b0cb9;
  font-family: 'Inter custom',sans-serif;
  text-align: left;
  font-weight: 600;
}
.termsAndConditionsDivBolder{
  color: #010b0cb9;
  font-family: 'Inter custom',sans-serif;
  text-align: left;
  font-weight: bolder;
}

@keyframes imageCycleLeft {
  0% { background-image: url('../public/img/ExampleChad1.png'); }
  25% { background-image: url('../public/img/ExampleChad3.png'); }
  50% { background-image: url('../public/img/ExampleChad4.png'); }
  75% { background-image: url('../public/img/ExampleChad2.png'); }
  100% { background-image: url('../public/img/ExampleChad1.png'); }
}

.circleImageContainerLeft{
  grid-column: 1;  
  grid-row: 2; 
  background-image: url('../public/img/TigFirstGIGACHAD.png');
  background-size: cover;
  border-radius: 100% 100%;
  border: 2px solid white;
  height: 25vw;
  width: 25vw;
  margin: auto;
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.3);
  animation: imageCycleLeft 40s infinite steps(1);
}

@keyframes imageCycleRight {
  0% { background-image: url('../public/img/TigFirstGIGACHAD.png'); }
  25% { background-image: url('../public/img/ExampleChad5.png'); }
  50% { background-image: url('../public/img/ExampleChad1.png'); }
  75% { background-image: url('../public/img/ExampleChad3.png'); }
  100% { background-image: url('../public/img/TigFirstGIGACHAD.png'); }
}

.circleImageContainerRight{
  grid-column: 4;  
  grid-row: 2; 
  background-image: url('../public/img/TigFirstGIGACHAD.png');
  background-size: cover;
  border-radius: 100% 100%;
  border: 2px solid white;
  height: 25vw;
  width: 25vw;
  margin: auto;
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.3);
  transform: scaleX(-1);
  animation: imageCycleRight 40s infinite steps(1);
}

.custom-cursor{
  cursor: url('../public/img/gigachadcursor.png'), auto;
}

.gigaMintContainer {
  display: grid;
  grid-template-columns: 33vw 17vw 17vw 33vw;
  grid-template-rows: 25vh 60vh 15vh;
}

.containerBackgroundLeftHalf{
  grid-column: 1 / 3;
  grid-row: 1 / 4;
  background-image: linear-gradient(#191970, #191970b4), url('../public/img/TigFirstGIGACHAD_websitecolumn.png');
  background-size: 100%;
}
.containerBackgroundRightHalf{
  grid-column: 3/6;
  grid-row: 1 / 4;
  background-image: linear-gradient(#191970, #191970b4), url('../public/img/TigFirstGIGACHAD_websitecolumn_right.png');
  background-size: 100%;
}

.mintContainer {
  grid-column: 2/4;  
  grid-row: 2; 
  background-color: #8e8ed677;
  border-radius: 30px;
  text-align: center;
  box-shadow: 0px 0px 20px 2px rgba(255, 255, 255, 0.3);
  overflow:auto;
}


@font-face {
  font-family: 'CustomSporty';
  src: url('../public/fonts/Darknet-K7xOX.ttf') format('truetype');
}

.mintButton {
  
  background-color: #008263;
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.3);
  color: #ffffff;
  cursor: url('../public/img/bicep.png'), auto;
  
  font-family: 'CustomSporty';
  font-size: 1vw;
  font-weight: 1;
  padding: .1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  width: fit-content;
  height: 2.5vw;
  min-width: 0px;
  overflow: hidden;
  

  
 
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;


  
}

.mintButton:hover,
.mintButton:focus{ 
  box-shadow: 0px 0px 20px 4px rgba(255, 255, 255, 0.3);
  cursor: url('../public/img/bicep.png'), auto;
}

.mintButton:active {
  transform: scale(0.95);
  cursor: url('../public/img/bicep.png'), auto;
}

.mintButton:disabled { 
  background-color: #00826462;
  color: #ffffff;
}

.mintContainerUpper{
  min-height: 70%;
  display: block;
  text-align: center;
  
}

.mintContainerLower{
  min-height: 30%;
}

.gigaChadsTitleContainer{
  grid-column: 2/4;
  grid-row: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

@font-face {
  font-family: 'CustomSportyBTE';
  src: url('../public/fonts/BTE-Sporty-DEMO.otf') format('opentype');
}

@keyframes titleMotion {
  0% { transform: perspective(500px) rotateY(0deg); }
  25% { transform: perspective(500px) rotateY(30deg); }  
  75% { transform: perspective(500px) rotateY(-30deg); }
  100% { transform: perspective(500px) rotateY(0deg); 
  }
}

.gigaChadsTitle{
  font-family: 'CustomSportyBTE';
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #008263, 0 0 30px #008263, 0 0 40px #008263, 0 0 55px #008263, 0 0 75px #008263;
  color: #008263;
  font-size: xxx-large;
  animation: titleMotion 30s infinite ease-out, titleMotion 30s infinite linear, titleMotion 30s infinite ease-out, titleMotion 30s infinite linear;
}

.countLeft{
  font-family: 'CustomSportyBTE';
  color: white;
  font-size: xx-large;
  padding-top: 4%;
  padding-bottom: 2%;
}

.address{
  font-family: 'CustomSporty';
  color: white;
  font-size: medium;
  padding-top: 2%;
  padding: 1%;
  max-width: 90%;
  margin:auto;
  background-color: #19197059;
  border-radius: 1dvh;
  box-shadow: 0px 0px 20px 2px rgba(255, 255, 255, 0.3);
}
.gasfees{
  font-family: 'CustomSporty';
  color:#191970;
  font-size: large;
  padding-bottom: 4%;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Regular.ttf') format('truetype');
}


.cost{
  font-family: 'CustomSporty';
  color:#191970;
  font-size: large;
  font-weight: 600;
  padding-top: 2%;
  max-width: 70%;
  margin:auto;
}

.desc{
  font-family: 'CustomSporty';
  color:#191970;
  font-size: .9dvi;
  padding: 1%;
  max-width: 90%;
  margin:auto;
  background-color: #AEA9BA;
  border-radius: 2dvh;
  font-weight: bolder;
  box-shadow: 0px 0px 20px 2px rgba(255, 255, 255, 0.3);
}

.clickToSecure{
  font-family: 'CustomSporty';
  color: white;
  font-size: medium;
  padding-top: 2%;
  padding-bottom: 4%;
}

.incrementerButton {
  background-color: #19197056;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.3);
  color: #ffffff;
  
  cursor: url('../public/img/bicep.png'), auto;
  font-family: 'CustomSporty';
  font-size: 16px;
  font-weight: 1;
  margin-top:2%;
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  padding: 0px 10px 00px 10px;

  
}

.incrementerButton:hover,
.incrementerButton:focus{ 
  box-shadow: 0px 0px 20px 4px rgba(255, 255, 255, 0.3);
  cursor: url('../public/img/bicep.png'), auto;
}

.incrementerButton:active {
  transform: scale(0.95);
  cursor: url('../public/img/bicep.png'), auto;
}

.incrementerButton:disabled { 
  background-color: #00826462;
  color: #ffffff;
}

.amountToMint{
  font-family: 'Roboto';
  color: white;
  font-size: x-large;
  padding-top: 4%;
  padding-bottom: 2%;
}


.gigaMintErrorText{
  text-align: center;
  font-size: large;
  padding: 1%;
  max-width: 90%;
  margin:auto;
  
}

.gigaMintErrorTextBG{
  width: 100%;
  text-align: center;
  overflow:auto;

  padding: 1%;
  max-width: 90%;
  margin:auto;
  margin-top: 10%;
  background-color: #c57587;
  border-radius: 2dvh;
  font-weight: bolder;
  box-shadow: 0px 0px 20px 2px rgba(255, 255, 255, 0.3);
}



.spinner {
  margin-left: 15%;
  width: 40px;
  height: 40px;
 float: left;
  display: flex;
  -webkit-animation: sk-rotate 2.0s infinite linear;
  animation: sk-rotate 2.0s infinite linear;
}

.spinner2 {
  margin-right: 15%;
  width: 40px;
  height: 40px;
 float: right;
  display: flex;
  -webkit-animation: sk-rotate 2.0s infinite linear;
  animation: sk-rotate 2.0s infinite linear;
}

.dot1, .dot2 {
  width: 40%;
  height: 40%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #1919706e;
  border-radius: 100%;
  box-shadow: 0px 0px 20px 2px rgba(255, 255, 255, 0.185);
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}



@keyframes glitch {
  0% { text-shadow: 2px 2px rgba(255, 0, 0, 0.438), -2px -2px rgba(0, 255, 0, 0.295), -2px 2px rgba(0, 0, 255, 0.295), 2px -2px rgba(255, 255, 0, 0.247); }
  20% { text-shadow: -2px -2px rgba(255, 0, 0, 0.295), 2px 2px rgba(0, 255, 0, 0.37), 2px -2px rgba(0, 0, 255, 0.288), -2px 2px rgba(255, 255, 0, 0.322); }
  40% { text-shadow: 2px -2px rgba(255, 0, 0, 0.274), -2px 2px rgba(0, 255, 0, 0.418), -2px -2px rgba(0, 0, 255, 0.301), 2px 2px rgba(255, 255, 0, 0.322); }
  60% { text-shadow: -2px 2px rgba(255, 0, 0, 0.329), 2px -2px rgba(0, 255, 0, 0.411), 2px 2px rgba(0, 0, 255, 0.315), -2px -2px rgba(255, 255, 0, 0.384); }
  80% { text-shadow: 2px 2px rgba(255, 0, 0, 0.301), -2px -2px rgba(0, 255, 0, 0.349), -2px 2px rgba(0, 0, 255, 0.301), 2px -2px rgba(255, 255, 0, 0.329); }
  100% { text-shadow: -2px -2px rgba(255, 0, 0, 0.342), 2px 2px rgba(0, 255, 0, 0.247), 2px -2px rgba(0, 0, 255, 0.267), -2px 2px rgba(255, 255, 0, 0.377); }
}

.releaseStage {
  animation: glitch 10s infinite;
}





/* Styles for smartphones */
@media (max-width: 912px) {
  .gigaMintContainer {
    display: grid;
    grid-template-columns: 10vw 40vw 40vw 10vw;
    grid-template-rows: 10vh 20vh 65vh 5vh;
  }


  .mintContainer {
    grid-column: 2/4;  
    grid-row: 3; 
    background-color: #8e8ed677;
    border-radius: 30px;
    text-align: center;
    box-shadow: 0px 0px 20px 2px rgba(255, 255, 255, 0.3);
    overflow: auto;
  }

  .circleImageContainerRight{
    grid-column: 3;  
    grid-row: 2; 
    
  }

    .circleImageContainerLeft{
      grid-column: 2;  
      grid-row: 2; }
      
      .containerBackgroundRightHalf{
        grid-row: 1/5;
      }
      .containerBackgroundLeftHalf{
        grid-row: 1/5;
      }

      .mintButton{
        width: 35%;
        height: 7vw;
        font-size: 2.2vw;
      }

      .desc{
        font-size: 2.5vw;
      }

      .address{
        font-size: 3vw;
      }

      .gigaChadsTitle{
        padding-top: 3vmin;
        font-size: 6vmin;
      }

      .cost{
        font-size: 3vmin;
      }
      .gasfees{
        font-size: 4vmin;
      }

      .clickToSecure{
        font-size: 4vmin;
      }

      
  
}
